import React, { useRef } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { Icon } from 'components/icon';
import { TieredMenu } from 'primereact/tieredmenu';
import { useAuth0 } from '@auth0/auth0-react';
import Avatar from './avatar';

export const Profile = () => {
  const menu = useRef<TieredMenu | null>(null);
  const { isAuthenticated, user, logout } = useAuth0();

  const items = [
    {
      label: 'Logout',
      command: () => {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      },
    },
  ];

  if (isAuthenticated) {
    return (
      <div className={cx(styles.profile)}>
        <Avatar className={cx(styles.avatar)} user={user || {}} />

        <div
          className={cx(styles.name)}
          onClick={(e) => menu?.current?.toggle(e)}
        >
          {user?.nickname}
          <span>
            <Icon
              className={cx(styles.dropdown)}
              size={'1.5rem'}
              name="expand_more"
            />
          </span>
        </div>
        <TieredMenu
          style={{
            marginTop: 22,
            width: '10rem',
          }}
          model={items}
          popup
          ref={menu}
        />
      </div>
    );
  }
  return <></>;
};
