import React from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { APIOptions, PrimeReactProvider } from 'primereact/api';
import { DialogProvider } from 'shared/providers/dialog';
import { ToastProvider } from 'shared/providers/toast';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  const value: Partial<APIOptions> = {
    inputStyle: 'filled',
    ripple: true,
  };

  return (
    <HelmetProvider>
      <PrimeReactProvider value={value}>
        <ToastProvider>
          <DialogProvider>
            <RouterProvider
              router={router}
              future={{ v7_startTransition: true }}
            />
          </DialogProvider>
        </ToastProvider>
      </PrimeReactProvider>
    </HelmetProvider>
  );
}

export default App;
