import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { rangeValues } from './constant';
import { useEffectUpdate } from 'hooks';
import { TimeRangeValue } from './models';
import { Calendar } from 'primereact/calendar';
import { Icon } from 'components/icon';
import { ChevronUpIcon } from 'primereact/icons/chevronup';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';

export interface TimeRangeProps {
  rangeValue: TimeRangeValue;
  from?: string;
  to?: string;
  onChange?: (
    value: TimeRangeValue,
    data: { from?: string; to?: string },
  ) => void;
}

export const TimeRange = ({
  rangeValue,
  from,
  to,
  onChange,
}: TimeRangeProps) => {
  const [value, setValue] = useState(rangeValue);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [fromTo, setFromToValue] = useState<{ from?: string; to?: string }>({
    from,
    to,
  });
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  useEffectUpdate(() => {
    setValue(rangeValue);
    setIsDropdownOpen(false);
  }, [rangeValue]);

  useEffectUpdate(() => {
    setFromToValue((prev) => ({ ...prev, from, to }));
  }, [from, to]);

  // Update state when dropdown is clicked (open/close)
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node; // Assert type as Node
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const onChangeHandler = (e: DropdownChangeEvent) => {
    setValue(e.value);
    if (typeof onChange === 'function') {
      onChange(
        e?.value,
        e.value === 'custom' ? fromTo : { from: undefined, to: undefined },
      );
    }
  };

  const onChangeFromHandler = (e: DropdownChangeEvent) => {
    setFromToValue((prev) => {
      console.log({ ...prev });
      console.log({ ...prev, from: e.value });
      return { ...prev, from: e.value };
    });
    if (typeof onChange === 'function') {
      onChange(value, { ...fromTo, from: e.value });
    }
  };

  const onChangeToHandler = (e: DropdownChangeEvent) => {
    setFromToValue((prev) => {
      console.log({ ...prev });
      console.log({ ...prev, to: e.value });
      return { ...prev, to: e.value };
    });
    if (typeof onChange === 'function') {
      onChange(value, { ...fromTo, to: e.value });
    }
  };

  return (
    <div className={styles.timeRangeWrapper}>
      <div className={cx(styles.timeRange)}>
        {value === 'custom' && (
          <div className={styles.timeRangeCustom}>
            <div className={styles.timeRangeDate}>
              <label>From</label>
              <Calendar
                showIcon
                style={{ width: '9rem' }}
                inputClassName={styles.input}
                value={fromTo.from ? new Date(fromTo.from) : undefined}
                iconPos={'right'}
                icon={<Icon size={'1.5rem'} name="calendar" />}
                placeholder={'MM/DD/YYYY'}
                onChange={onChangeFromHandler}
              />
            </div>
            <div className={styles.timeRangeDate}>
              <label>To</label>
              <Calendar
                showIcon
                style={{ width: '9rem' }}
                minDate={fromTo.from ? new Date(fromTo.from) : undefined}
                inputClassName={styles.input}
                value={fromTo.to ? new Date(fromTo.to) : undefined}
                iconPos={'right'}
                icon={<Icon size={'1.5rem'} name="calendar" />}
                placeholder={'MM/DD/YYYY'}
                onChange={onChangeToHandler}
              />
            </div>
          </div>
        )}
        <label className={cx(styles.label)}>Time range:</label>
        <div
          ref={dropdownRef}
          className={cx(styles.timeRangeDropdownContainer, {
            [styles.open]: isDropdownOpen,
          })}
        >
          <Dropdown
            value={value}
            style={{ minWidth: '8rem' }}
            onChange={onChangeHandler}
            options={rangeValues}
            optionLabel="label"
            placeholder="Select..."
            className={cx(styles.filter)}
            dropdownIcon={() => {
              const iconStyle = {
                color: isDropdownOpen ? '#F25844' : 'none',
              };
              return isDropdownOpen ? (
                <ChevronUpIcon style={iconStyle} />
              ) : (
                <ChevronDownIcon style={iconStyle} />
              );
            }}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          />
        </div>
      </div>
    </div>
  );
};
