import React from 'react';
import { PageManager, RoutePage } from './shared/utils';
import DashboardPage from 'pages/dashboard';
import ClusterPage from 'pages/check-summary/current-cluster';
import ReportPage from 'pages/reports/current-report';
import Shell from '../shell';
import ConnectPage from 'pages/reports/connect';

export const ConnectPageConfig = ConnectPage({
  prefix: `${ClusterPage?.route?.path}`,
});

export const PAGE_MANAGER = new PageManager(<Shell />, {
  root: {
    index: DashboardPage,
    dashboard: {
      index: DashboardPage,
      cluster: {
        index: ClusterPage,
        terminal: ConnectPageConfig,
        report: ReportPage({
          prefix: `${ClusterPage?.route?.path}/reports`,
          showHomePageInBreadcrumbs: true,
        }),
      },
    },
  },
});

export const pages: RoutePage[] = PAGE_MANAGER.getPages();
