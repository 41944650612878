import React from 'react';
import cx from 'classnames';
import { Header } from 'components/header';
import styles from './app.module.scss';
import {
  matchPath,
  Outlet,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { AuthProvider } from 'shared/providers/auth';
import { CheckSummaryProvider } from 'pages/dashboard/context/provider';
import { CurrentClusterProvider } from 'pages/check-summary/current-cluster/context/provider';
import { CurrentReportProvider } from 'pages/reports/current-report/context/provider';
import { ConnectPageConfig } from 'router/register-pages';
import { ProgressSpinner } from 'primereact/progressspinner';

export const Layout = ({ children }: React.PropsWithChildren) => {
  const location = useLocation();
  const [query] = useSearchParams();

  const isCodeValidatePage = query.get('code');

  const isConnectPage =
    ConnectPageConfig.route.path &&
    matchPath(ConnectPageConfig.route.path, location.pathname);

  if (isCodeValidatePage) {
    return (
      <div className="absolute">
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <ProgressSpinner />
        </div>
      </div>
    );
  }
  return (
    <div className={cx(styles.app, 'p-input-filled')}>
      {!isConnectPage && <Header></Header>}
      <div
        id={'mainScrollable'}
        style={{ marginTop: isConnectPage ? 0 : 64, height: '100%' }}
        className={cx(styles.appContainer, 'scrollable')}
      >
        {children}
      </div>
    </div>
  );
};

function Shell() {
  return (
    <AuthProvider>
      <CheckSummaryProvider>
        <CurrentClusterProvider>
          <CurrentReportProvider>
            <Layout>
              <Outlet />
            </Layout>
          </CurrentReportProvider>
        </CurrentClusterProvider>
      </CheckSummaryProvider>
    </AuthProvider>
  );
}

export default Shell;
