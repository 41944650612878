import { TimeRangeValue } from 'components/time-range/models';
import {
  ClustersService,
  ClusterDashboardSummary,
  ClusterDetailsResponse,
} from 'shared/api/client';
import { getDateRange, getProperty, normalizeArray } from 'shared/utils';
import { SummaryCluster } from './models';
import { Params } from 'react-router-dom';

export const getDashboard = (executionDate: string) => () => {
  return ClustersService.getClusterDashboardApiV1ClustersDashboardGet({
    executionDate: executionDate,
  }).then((res) => {
    return normalizeArray({
      array: res?.clusters || [],
      key: 'id' as keyof ClusterDashboardSummary,
      iterator: (x) => {
        const key = 'id' as keyof ClusterDashboardSummary;
        return {
          clusterId: getProperty(x, key),
          name: x?.name,
          noResultCount: x?.no_result_count,
          groupedData: x?.grouped_data,
          failedCount: x?.failed_count,
          reportStatus: x?.report_status,
          isHealthy: x?.is_healthy,
          dateBy: executionDate,
          severity: Object.keys(x?.grouped_data).filter(
            (s) => !!x?.grouped_data[s],
          ),
          priority: Object.keys(x?.grouped_data).filter(
            (s) => !!x?.grouped_data[s],
          ),
        } as SummaryCluster;
      },
    });
  });
};

interface GetChecksEvolution {
  clusterId?: string;
  suiteId?: string;
  option: TimeRangeValue;
  customStartDate?: string;
  customEndDate?: string;
}

export const getChecksEvolution = ({
  option,
  suiteId,
  clusterId,
  customEndDate,
  customStartDate,
}: GetChecksEvolution) => {
  const dateRange = getDateRange(option, customStartDate, customEndDate);
  if (dateRange) {
    const { startDate, endDate } = dateRange;
    return ClustersService.getChecksEvolutionApiV1ClustersChecksEvolutionPost({
      requestBody: {
        cluster_id: clusterId,
        suite_id: suiteId,
        start_date: startDate,
        end_date: endDate,
      },
    }).then((res) => {
      return normalizeArray({
        array: res?.series || [],
        key: 'datetime',
      });
    });
  }
};

export const getClusterDetails = ({ clusterId }: Params<string>) => {
  if (clusterId) {
    return ClustersService.getClusterDetailsApiV1ClustersClusterIdGet({
      clusterId,
    }).then((res) => {
      const { name, last_updated, rts_allowed, connection_status } = res;
      return {
        name,
        last_updated: last_updated,
        rts_allowed: rts_allowed,
        connection_status: connection_status,
      } as ClusterDetailsResponse;
    });
  }
  return Promise.reject(`The "clusterId" parameter is missing`);
};
