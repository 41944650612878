import React from 'react';
import styles from './styles.module.scss';
import 'primeicons/primeicons.css';

interface StatusIndicatorProps {
  color: string;
  statusText: string;
  title: string;
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({
  color,
  statusText,
  title,
}) => {
  return (
    <div className={styles.statusContainer}>
      <i
        className="pi pi-circle-fill"
        style={{ color, fontSize: '0.625rem' }}
        title={title}
      ></i>
      <span
        className={
          statusText === 'Online'
            ? styles.onlineStatusText
            : styles.offlineStatusText
        }
      >
        {statusText}
      </span>
    </div>
  );
};

export default StatusIndicator;
