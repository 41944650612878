export const downloadStringTemplate = (data = '', fileName = '') => {
  // Create a blob from the processed data
  const file = new Blob([data], { type: 'text/plain' });

  // Create a link element and set its href to the blob URL
  const element = document.createElement('a');
  element.href = URL.createObjectURL(file);
  element.download = fileName;

  // Append the link to the document, click it to start download, and remove it
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const processConfigData = (data: string) => {
  // Remove leading and trailing double quotes
  let cleanedData = data.replace(/^"(.*)"$/, '$1');

  // Replace all \n characters with actual new lines for proper formatting
  cleanedData = cleanedData.replace(/\\n/g, '\n');

  return cleanedData;
};
