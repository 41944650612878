import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { Icon } from 'components';
import { useNavigate } from 'react-router-dom';
import { SummaryCluster } from 'pages/dashboard/shared/models';
import { VirtualScroller } from 'primereact/virtualscroller';
import { Search } from 'components/search';
import { FilterMeta, FilterTags } from 'components/filter-tags';

interface CheckSummaryClustersProps {
  clusters: Record<string, SummaryCluster>;
  filters: FilterMeta;
  onClear?: (key: keyof SummaryCluster, value: string) => void;
}

export const CheckSummaryClusters = ({
  clusters,
  filters,
  onClear,
}: CheckSummaryClustersProps) => {
  const navigate = useNavigate();

  const onClickCluster =
    (id: string) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      navigate(`/cluster/${id}`);
    };

  const clustersDataList = Object.entries(clusters || {})?.map(([k, v]) => ({
    label: v.name,
    value: v.clusterId,
    failedCount: v.failedCount,
    errorCount: v.noResultCount,
    _origin: v,
  }));

  const filteredClustersDataList = clustersDataList.filter((item) => {
    return Object.entries(filters).every(([field, filter]) => {
      const itemValue = ((item || {})._origin || {})[
        field as keyof SummaryCluster
      ];
      const filterValue = filter?.value;

      if (Array.isArray(filterValue)) {
        if (Array.isArray(itemValue)) {
          return filterValue
            .map(String)
            .some((val) => itemValue.map(String).includes(val));
        } else {
          return filterValue.map(String).includes(String(itemValue));
        }
      } else if (filterValue) {
        if (Array.isArray(itemValue)) {
          return itemValue.map(String).includes(String(filterValue));
        }
        return itemValue === String(filterValue);
      }
      return true;
    });
    // return Object.entries(filters).every(([filed, filter]) => {
    //   return filter?.value
    //     ? String(
    //         ((item || {})._origin || {})[filed as keyof SummaryCluster],
    //       ) === String(filter.value)
    //     : true;
    // });
  });

  return (
    <div className={cx(styles.dataListView)}>
      <div className={cx(styles.header)}>
        <h4 className={cx(styles.title)}>Clusters</h4>

        {/* <div className={cx(styles.sortBy)}>
          <label className={cx(styles.label)}>Sort By:</label>
          <Dropdown
            value={null}
            //onChange={(e) => setSelectedCity(e.value)}
            options={[{ label: '' }]}
            optionLabel="name"
            placeholder="Select..."
            className={cx(styles.sortByFilter)}
          />
        </div> */}
      </div>
      {Object.keys(filters || {})?.length > 0 && (
        <div className={cx(styles.headerFilter)}>
          <FilterTags
            titles={{
              isHealthy: 'is Healthy',
              priority: 'Priority',
              severity: 'Severity',
              reportStatus: 'Report Status',
            }}
            onClear={onClear}
            filters={filters || {}}
          />
        </div>
      )}
      <Search
        value=""
        className={styles.withSearch}
        data={filteredClustersDataList}
        searchFields={['label']}
        resultsComponent={(res, value) => {
          const sortedRes = res.sort(
            (a, b) =>
              b.failedCount + b.errorCount - (a.failedCount + a.errorCount),
          );
          return (
            <div className={styles.viewListBox}>
              <div className="absolute">
                <VirtualScroller
                  items={sortedRes}
                  itemSize={55}
                  autoSize
                  itemTemplate={(opt, scollOptions) => {
                    return (
                      <div
                        onClick={onClickCluster(opt?.value)}
                        className={cx(styles.itemList, styles.link, {
                          [styles.even]: scollOptions.even,
                        })}
                      >
                        <h6 className={styles.name}>{opt.label}</h6>
                        <div className={styles.data}>
                          <div className={styles.item}>
                            <Icon
                              className={cx(styles.icon, styles.fail)}
                              size={'1.5rem'}
                              name="fail"
                            />
                            {opt?.failedCount}
                          </div>
                          <div className={styles.item}>
                            <Icon
                              className={cx(styles.icon, styles.error)}
                              size={'1.5rem'}
                              name="error"
                            />
                            {opt.errorCount}
                          </div>
                        </div>
                      </div>
                    );
                  }}
                  //autoSize
                  className={cx(styles.list)}
                  style={{ height: '100%' }}
                />
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};
