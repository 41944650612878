import React, { useLayoutEffect, useState } from 'react';

import { usePageContext } from 'components';
import { RoutePageData } from 'router/shared/models';
import { useParams } from 'react-router-dom';
import { ErrorPage } from 'pages/error-page';

import { ClusterDetailsResponse } from 'shared/api/client';

import { getClusterDetails } from 'pages/dashboard/shared/requests';
import { useToast } from 'shared/providers/toast/context';
import { Helmet } from 'react-helmet-async';

interface StateProps {
  reportId?: string;
  isLoading?: boolean;
}

type Params = {
  clusterId: string;
};

export const Connect = () => {
  const { showApiError } = useToast();
  const { state, error404 } = usePageContext<StateProps>();
  const { clusterId } = useParams<Params>();
  const [clusterDetails, setClusterDetails] =
    useState<ClusterDetailsResponse>();

  useLayoutEffect(() => {
    if (clusterId) {
      getClusterDetails({ clusterId })
        .then((res) => {
          if (res) {
            setClusterDetails(res);
          } else {
            error404();
          }
        })
        ?.catch((e) => {
          showApiError(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusterId]);

  if (state?.isError404) {
    return <ErrorPage />;
  }

  const clusterName = clusterDetails?.name;

  const terminalUrl = `https://${window.__RUNTIME_CONFIG__.JIT_HOST}/${clusterName}/`;

  return (
    <>
      <Helmet>
        <title>{`Terminal: ${clusterName} `}</title>
      </Helmet>
      <div className="absolute">
        {clusterName && (
          <iframe
            style={{ border: 'none' }}
            title="terminal"
            className="absolute"
            width={'100%'}
            height={'100%'}
            src={terminalUrl}
          ></iframe>
        )}
      </div>
    </>
  );
};

export default function ConnectConfig({
  prefix,
}: {
  prefix: string | undefined;
}) {
  return {
    wrapToPage: false,
    route: {
      path: `${prefix}/terminal`,
      Component: Connect,
    },
  } as RoutePageData;
}
